$(document).ready(function() {
  $('#profile').change(function(e) {
    e.preventDefault();

    $('#search-form').submit();
  });

  $('#search_for').change(function(e) {
    e.preventDefault();

    $('#search_for_field').val(this.value);
  });
});
