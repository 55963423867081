var inputmask = require('maskedinput/dist/inputmask/jquery.inputmask');
var locale = $('html')[0].lang;

$(function() {
  var date_format;

  switch(locale) {
  case 'pt-BR':
    date_format = '99/99/9999';
    break;
  case 'es':
    date_format = '9{1,2}/9{1,2}/9999';
    break;
  case 'en':
    date_format = '9999-99-99';
    break;
  }

  $('.date').inputmask(date_format);
  $('.hour').inputmask('99:99');
});
